import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { gsap } from 'gsap'

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Texture
const textureLoader = new THREE.TextureLoader()

// Raycaster
const raycaster = new THREE.Raycaster()
const mouse = new THREE.Vector2()

const rgbeLoader = new RGBELoader()
const gltfLoader = new GLTFLoader()

const origin = new THREE.Vector3(0, 0, 0);

/* const gui = new GUI() */
let scaleFactor = 1
const maxRotationFactor = 2

const originalPositions = new Map();
const originalRotations = new Map();

let rotationSpeed = 0


const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const params = {
    scaleFactor: scaleFactor
}

const parameters = {
    materialColor: '#dddddd'
}


// GUI controls for scale factor
/* gui.add(params, 'scaleFactor', 1, 10).name('Explosion Scale').onChange((value) => {
    scaleFactor = value
    updateExplosionEffect()
}) */


// Updated function to apply the "explosion" effect
function applyExplosionEffect(object) {

    const scaleFactor = params.scaleFactor;
    const adjustedRotationFactor = Math.max(0, (scaleFactor - 1) * maxRotationFactor)

    object.traverse((child) => {
        if (child.isMesh || child.isGroup) {
            const originalPosition = originalPositions.get(child)
            const originalRotation = originalRotations.get(child)

            if (originalPosition && originalRotation) {

                const direction = new THREE.Vector3().subVectors(originalPosition, origin)
                
                const distance = direction.length()
                direction.multiplyScalar(scaleFactor)

                if (adjustedRotationFactor > 0) {
                    const targetRotation = new THREE.Euler(
                        (Math.random() - 0.5) * adjustedRotationFactor * distance,
                        (Math.random() - 0.5) * adjustedRotationFactor * distance,
                        (Math.random() - 0.5) * adjustedRotationFactor * distance
                    );

                    child.rotation.x = THREE.MathUtils.lerp(child.rotation.x, targetRotation.x, rotationSpeed)
                    child.rotation.y = THREE.MathUtils.lerp(child.rotation.y, targetRotation.y, rotationSpeed)
                    child.rotation.z = THREE.MathUtils.lerp(child.rotation.z, targetRotation.z, rotationSpeed)
                }

                child.position.copy(origin).add(direction)
                
            }
        }
    });
}

const objectsDistance = 6

const animatedMeshes = []

function updateExplosionEffect() {
    scene.traverse((object) => {
        if (object.isMesh || object.isGroup) {
            console.log(scaleFactor)
            applyExplosionEffect(object)
        }
    });
}

// Load the environment map (HDR)
rgbeLoader.load('./kloppenheim_02_puresky_1k.hdr', (environmentMap) => {
    environmentMap.mapping = THREE.EquirectangularReflectionMapping
    // Only set the environment for reflections, not the background
    scene.environment = environmentMap
    // Optional: Set background color or leave it neutral (transparent/black)
    scene.background = null // or scene.background = new THREE.Color(0x000000); for solid color

    // Load the model
    gltfLoader.load('/icosaedro.glb', (gltf) => {
        
        gltf.scene.scale.set(1, 1, 1)
        
        // Process all objects recursively
        gltf.scene.traverse((child) => {
            
            processObject(child)  // Process each mesh and group

            if (child.isMesh) {
                // Apply the environment map to the material of the mesh
                child.material.envMap = environmentMap
                child.material.envMapIntensity = 1 // Adjust the intensity if needed
                child.material.needsUpdate = true // Ensure the material updates
            }
            
        });

        animatedMeshes.push(gltf.scene)
        scene.add(gltf.scene)
    });
});

// Function to process objects (both groups and meshes)
function processObject(object) {
    
    object.traverse((subObject) => {
        
        // Process each mesh individually
        subObject.scale.set(0.99, 0.99, 0.99)
        
         if( subObject.material ) {
            subObject.material.metalness = 1 // Assuming 'metalness' property exists on the material
            subObject.material.roughness = 0.0
        } 
        

        // Store the original position and rotation of each fragment
        originalPositions.set(subObject, subObject.position.clone())
        originalRotations.set(subObject, subObject.rotation.clone())

        applyExplosionEffect(subObject) // Apply initial effect
        
    });
}


// Function to animate the explosion and reset
function animateExplosion() {
    gsap.to(params, {
        scaleFactor: 10, // Increase to 10
        duration: 2, // Animation duration for explosion
        ease: "circ",
        onUpdate: updateExplosionEffect,
        onComplete: () => {
            gsap.to(params, {
                scaleFactor: 1, // Return back to 1
                duration: 0.8, // Animation duration for collapse
                delay: 5, // Delay before collapsing back
                ease: "sine",
                onUpdate: updateExplosionEffect
            })
        }
    })
}


// Event listener for mouse clicks
window.addEventListener('click', (event) => {
    mouse.x = (event.clientX / sizes.width) * 2 - 1
    mouse.y = -(event.clientY / sizes.height) * 2 + 1

    // Cast ray to detect click on object
    raycaster.setFromCamera(mouse, camera)
    const intersects = raycaster.intersectObjects(scene.children, true)

    if (intersects.length > 0) {
        animateExplosion()
    }
});


/**
 * Particles as Vertical Lines
 */

const particlesCount = 80

// Define the dimensions of the vertical lines
const lineWidth = 0.008  // Width of each line (horizontal)
const lineHeight = 0.008  // Height of each line (vertical)

// Create geometry for each particle (a vertical rectangle)
const planeGeometry = new THREE.PlaneGeometry(lineWidth, lineHeight)

// Create a group to hold all the particles (lines)
const particlesGroup = new THREE.Group()

for (let i = 0; i < particlesCount; i++) {
    // Random position for each particle
    const x = (Math.random() - 0.5) * 8
    const y = objectsDistance * 0.5 - Math.random() * objectsDistance
    const z = (Math.random() - 0.5) * 8

    // Create a mesh for the particle
    const particleMaterial = new THREE.MeshBasicMaterial({
        color: parameters.materialColor,
        side: THREE.DoubleSide
    });
    
    const particle = new THREE.Mesh(planeGeometry, particleMaterial);
    particle.position.set(x, y, z)
    
    particlesGroup.add(particle)
}

// Add the group of particles (lines) to the scene
scene.add(particlesGroup)

// Store the particle speeds
const particleSpeeds = new Float32Array(particlesCount);

for (let i = 0; i < particlesCount; i++) {
    // Random speed for each particle between 0.002 and 0.01
    particleSpeeds[i] = 0.002 + Math.random() * 0.01
}


window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 10
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Animate camera
    camera.position.y = -scrollY / sizes.height * objectsDistance;

    const parallaxX = cursor.x * 0.5;
    const parallaxY = -cursor.y * 0.5;
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime;

    // Animate meshes (if any)
    for (const mesh of animatedMeshes) {
        mesh.rotation.x += deltaTime * 0.2;
        mesh.rotation.y += deltaTime * 0.1;
    }

    // Update the vertical lines (particles) positions
    for (let i = 0; i < particlesGroup.children.length; i++) {
        const particle = particlesGroup.children[i];
        particle.position.y += particleSpeeds[i]; // Move upwards based on speed

        // Reset the particle if it's out of view
        if (particle.position.y > objectsDistance * 0.5) {
            particle.position.y = -objectsDistance * 0.5; // Reset position to below view
        }
    }

    // Render the scene
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};


tick()